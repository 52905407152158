<template>
    <transition name="fade">
        <div v-if="loaded" class="container mt-3 mb-3">
            <h2 id="contact-form">{{config.label}}</h2>
            <b-card v-if="config && !finished">
                <b-form @submit="onSubmit">
                    <div class="position-absolute" style="width: 100px; right: 0">
                        <small>*) Pflichtfeld</small>
                        <div class="clearfix"></div>
                    </div>
                    <b-form-group label="Ihr Name*" label-for="input-2">
                        <b-form-input v-model="name" name="name"
                                      placeholder="Geben Sie Ihren Namen ein"
                                      required></b-form-input>
                    </b-form-group>
                    <b-form-group label="E-Mail-Adresse*:" label-for="input-2">
                        <b-form-input v-model="mail" name="mail"
                                      placeholder="Geben Sie Ihre E-Mail-Adresse ein"
                                      required
                                      type="email"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Betreff*" label-for="input-2">
                        <b-form-input v-model="subject" name="subject"
                                      placeholder="Geben Sie den Betreff ein"
                                      required></b-form-input>
                    </b-form-group>
                    <b-form-group label="Nachricht*" label-for="input-2">
                        <b-textarea v-model="message" name="message"
                                    placeholder="Geben Sie die Nachricht ein"
                                    required></b-textarea>
                    </b-form-group>
                    <b-form-checkbox
                            v-model="confirm" name="confirm" required>
                        Zustimmung zur elektronischen Verarbeitung der Daten*<br/>
                        <small>Die Informationen zum Datenschutz und zur Verarbeitung meiner personenbezogenen Daten
                            habe ich gelesen und bin damit einverstanden.</small>
                    </b-form-checkbox>
                    <b-form-checkbox
                            v-model="copy" name="copy">
                        Kopie an mich <br/>
                        <small>Eine Kopie der Nachricht soll an mich versandt werden.</small>
                    </b-form-checkbox>
                    <hr/>
                    <b-button type="submit" variant="success">Abschicken</b-button>
                    <b-button type="reset" class="float-right" variant="danger">Zurücksetzen</b-button>
                </b-form>
            </b-card>
            <div v-else>
                <b-alert variant="success" show>
                    <b>Nachricht erfolgreich abgeschickt:</b> Vielen Dank für Ihre Nachricht an Ski Team Freiberg e.V.
                    <br/>
                    Wir werden diese bearbeiten und uns schnellstmöglich bei Ihnen melden.
                </b-alert>
                <a href="/">zurück zur Startseite</a>
            </div>
        </div>
    </transition>
</template>

<script>
    import api from '../apis/contact'

    export default {
        name: "Contact",
        data() {
            return {
                loaded: false,
                config: null,
                name: null,
                mail: null,
                subject: null,
                message: null,
                confirm: false,
                copy: false,
                finished: false
            }
        },
        created() {
            api.getContactForm(this.$route.params.contactId).then((data) => {
                this.config = data.data;
                this.loaded = true;
            }).catch(() => {
                this.$router.push({name: '404'})
            })
        },
        mounted() {
            setTimeout(() => {
                this.$smoothScroll({
                    scrollTo: document.getElementById('contact-form'),
                    duration: 1000,
                    offset: -100,
                })
            }, 500)
        },
        methods: {
            onSubmit(event) {
                event.preventDefault();
                api.createContact(this.$route.params.contactId, this.name, this.mail, this.subject, this.message, this.copy, this.confirm)
                    .then(() => {
                        this.finished = true;
                    }).catch(() => {
                    this.$router.push({name: '500'})
                })
            }
        }
    }
</script>

<style scoped>

</style>
