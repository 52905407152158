import api from './api';

const actions = {
    getPage (uuid) {
        return api.get(`/node/page/${uuid}`);
    },
    getNode(id) {
        return api.get(`/node/${id}?_format=json`);
    },
    getPageWithNodeId (nodeId) {
        return api.get(`/node/page?filter[drupal_internal__nid]=${nodeId}`);
    },
    getPages (params) {
        return api.getAll(`/node/page`, params);
    },
    getArticle (uuid) {
        return api.get(`/node/article/${uuid}`);
    },
    getArticles (params) {
        return api.get(`/node/article`, params);
    },
    getContent(url) {
        let _url = new URL(url);
        return api.get(_url.pathname + _url.search);
    },
    getFileContent(uuid) {
        return api.get(`file/file/${uuid}`);
    },
    getMenus(menu) {
        return api.get(`/api/menu_items/${menu}`);
    }
};
export default actions
