import _axios from 'axios'

const axios = _axios.create({
    baseURL: "/stf/",
    withCredentials: false,
    header: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
});

const actions = {
    getAll (endpoint, params) {
        return axios.get(endpoint, params);
    },
    get (endpoint) {
        return axios.get(endpoint);
    },
    post (endpoint, data) {
        return axios.post(endpoint, data);
    }

};
export default actions
