<template>
    <div class="root">
        <div id="page-top"></div>
        <!-- Navigation-->
        <nav class="navbar navbar-expand-lg navbar-light fixed-top py-3" id="mainNav"
             v-on-clickaway="closeExpanded"
             :class="{'navbar-scrolled': scrollPosition > 50}">
            <div class="container">
                <div style="position: relative; left: -10px; width: 50px;">
                    <transition name="fade">
                        <SmoothImg v-if="scrollPosition > 200" width="50px" :src="require('./assets/img/stf/logo-small.png')"/>
                    </transition>
                </div>
                <a class="navbar-brand js-scroll-trigger" @click="navigateToFrontpage('page-top')" href="/stf" v-smooth-scroll>
                    Skiteam Freiberg e.V.
                </a>
                <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
                        data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
                        aria-label="Toggle navigation"
                        @click="navExpanded = !navExpanded"
                ><span class="navbar-toggler-icon"></span></button>
                <div :class="{'collapse': !navExpanded}"
                     class="navbar-collapse" id="navbarResponsive">
                    <ul class="navbar-nav ml-auto my-2 my-lg-0">
                        <li class="nav-item" v-for="menu in menus" v-bind:key="menu.key">
                            <a v-if="menu.absolute.includes('#')"
                               @click="navigate(menu)"
                               class="nav-link js-scroll-trigger"
                               :href="menu.alias">
                                {{menu.title}}
                            </a>
                            <a v-else-if="menu.absolute.startsWith('http')" class="nav-link" target="_blank"
                               :href="menu.absolute">
                                {{menu.title}}
                            </a>
                            <router-link v-else class="nav-link js-scroll-trigger"
                                         :to="{path: `/${menu.alias}`}">
                                {{menu.title}}
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        <!-- Masthead-->
        <header class="masthead">
            <div class="container h-100">
                <div class="row h-100 align-items-center justify-content-center text-center">
                    <div class="col-lg-10 align-self-end">
                        <SmoothImg clazz="d-lg-none mx-auto" width="20rem" :src="require('./assets/img/stf/logo.png')"/>
                        <SmoothImg clazz="d-none d-lg-block mx-auto" width="30rem" :src="require('./assets/img/stf/logo.png')"/>
                        <hr class="divider my-4"/>
                    </div>
                    <div class="col-lg-8 align-self-baseline">
                        <p class="text-white-75 font-weight-light mb-5"><b>Skiteam Freiberg e.V.</b> bietet abwechslungsreiche und gesellige Aktivitäten rund um das <b>Skifahren</b> und <b>Snowboarden</b>.</p>
                        <a class="btn btn-primary btn-xl js-scroll-trigger"  @click="navigateToFrontpage('about')" v-smooth-scroll>Über uns</a>
                    </div>
                </div>
            </div>
        </header>
        <router-view></router-view>
        <section v-if="impressum" class="page-section bg-dark text-white" style="font-size: 0.8rem" id="impressum">
            <div class="container">
                <div class="row">
                    <div class="col" v-html="impressum.body[0].value"></div>
                </div>
            </div>
        </section>
        <footer class="bg-light py-5">
            <div class="container">
                <div class="small text-center text-muted">Copyright © {{date()}} - Skiteam Freiberg e.V.</div>
            </div>
        </footer>
    </div>
</template>

<script>

    import {mapGetters} from "vuex";
    import SmoothImg from "./components/SmoothImg";
    import {directive as onClickaway} from 'vue-clickaway';
    import moment from "moment";

    export default {
        name: 'App',
        directives: {
            onClickaway: onClickaway,
        },
        components: {SmoothImg},
        computed: {
            // mix the getters into computed with object spread operator
            ...mapGetters([
                'impressum',
                'menus'
            ])
        },
        data() {
            return {
                scrollPosition: null,
                navExpanded: false,
            }
        },
        mounted() {
            this.$store.dispatch("getImpressum");
            this.$store.dispatch("getMenus");
            window.addEventListener('scroll', this.updateScroll);
            this.$router.afterEach(() => {
                if (this.navExpanded) {
                    this.navExpanded = false;
                }
            })
        },
        methods: {
            date() {
                return moment().format('Y');
            },
            updateScroll() {
                this.scrollPosition = window.scrollY
            },
            closeExpanded() {
                if (this.navExpanded) {
                    this.navExpanded = false;
                }
            },
            navigateToFrontpage(to) {
                this.$router.push({path: "/"})
                this.$smoothScroll({
                    scrollTo: document.getElementById(to || "page-top"),
                    duration: 1000,
                    offset: 0,
                })
            },
            navigate(menu) {
                this.$router.push({path: "/" + menu.alias}).then(() => {
                    console.log("scroll to id", menu.alias);
                    let id = menu.alias.split("#")[1];
                    this.$smoothScroll({
                        scrollTo: document.getElementById(id),
                        duration: 1000,
                        offset: -100,
                    })
                })
            }
        }
    }
</script>

<style lang="scss">
    body {

    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .3s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }

</style>
