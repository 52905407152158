import Vue from 'vue'
import Vuex from 'vuex'
import nodeApi from '../apis/node'
import activitiesApi from '../apis/activities'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        impressum: null,
        articles: [],
        menus: [],
        page: null,
        activities: []
    },
    getters: {
        impressum(state) {
            return state.impressum;
        },
        articles(state) {
            return state.articles;
        },
        activities(state) {
            return state.activities;
        },
        menus(state) {
            return state.menus;
        },
        page(state) {
            return state.page;
        },
    },
    mutations: {
        SET_IMPRESSUM(state, impressum) {
            state.impressum = impressum;
        },
        SET_ARTICLES(state, articles) {
            state.articles = articles;
        },
        SET_ACTIVITIES(state, activities) {
            state.activities = activities;
        },
        SET_PAGE(state, page) {
            state.page = page;
        },
        SET_MENUS(state, menus) {
            state.menus = menus.sort((menu) => menu.weight);
        },
    },
    actions: {
        async getActivities({commit}) {
            return activitiesApi.getActivities()
                .then((response) => {
                    let activities = response.data;
                    commit("SET_ACTIVITIES", activities);
                    return activities;
                });
        },
        async getNode({commit}, id) {
            return nodeApi.getNode(id)
                .then((response) => {
                    let page = response.data;
                    console.log("TEST", page)
                    commit("SET_PAGE", page);
                    return page;
                });
        },
        async getImpressum({commit}) {
            return nodeApi.getNode("1")
                .then((response) => {
                    let impressum = response.data;
                    commit("SET_IMPRESSUM", impressum);
                });
        },
        async getArticles({commit}) {
            return nodeApi.getArticles()
                .then((response) => {
                    let articles = response.data.data;
                    commit("SET_ARTICLES", articles);
                    return articles;
                });
        },

        async getPage({commit}, nodeId) {
            return nodeApi.getPageWithNodeId(nodeId)
                .then((response) => {
                    let page = response.data.data[0];
                    commit("SET_PAGE", page);
                    return page;
                });
        },
        async getMenus({commit}) {
            return nodeApi.getMenus("landingpage")
                .then((response) => {
                    let menus = response.data;
                    commit("SET_MENUS", menus);
                    return menus;
                });
        }
    },
    modules: {}
})
