<template>
    <div>
        <transition name="fade">
            <img :class="clazz" :style="{ 'max-width': width}" v-bind:src="src" v-on:load="onLoaded" v-show="loaded">&nbsp;
        </transition>
    </div>
</template>

<script>
    export default {
        name: "SmoothImg",
        props: ['src', 'clazz', 'width'],
        data() {
            return {
                loaded: false
            }
        },
        methods: {
            onLoaded() {
                this.loaded = true;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
    {
        opacity: 0;
    }
</style>
