import Vue from 'vue'
import Router from 'vue-router'
import FrontPage from '../views/Frontpage'
import Page from "../views/Page";
import Contact from "../views/Contact";
import _404 from "../views/errors/404";
import _500 from "../views/errors/500";

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/stf',
            redirect: '/'
        },
        {
            path: '/',
            name: 'FrontPage',
            component: FrontPage
        },
        {
            path: '/page/:nodeId',
            name: 'Page',
            component: Page
        },
        {
            path: '/contact/:contactId',
            name: 'Contact',
            component: Contact
        },
        {
            path: '/error/404',
            name: '404',
            component: _404
        },
        {
            path: '/error/500',
            name: '500',
            component: _500
        }
    ]
})
