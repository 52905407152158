import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import './scss/styles.scss'
import { BootstrapVue } from 'bootstrap-vue'
require('vue-image-lightbox/dist/vue-image-lightbox.min.css');
import VueLazyLoad from 'vue-lazyload'
Vue.use(VueLazyLoad)
Vue.use(BootstrapVue)

import VueSmoothScroll from 'vue2-smooth-scroll'
Vue.use(VueSmoothScroll);

Vue.config.productionTip = false;

new Vue({
  store,
  render: h => h(App),
  router
}).$mount('#app')
