<template>
    <div>
        <section class="page-section bg-primary" id="about">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8 text-center">
                        <h2 class="text-white mt-0">Wir sind Skiteam Freiberg e.V.</h2>
                        <hr class="divider light my-4"/>
                        <p class="text-white-50 mb-4">Das <b>Skiteam Freiberg e.V.</b> wurde 2020 gegründet und ist ein
                            junger und eigenständiger Verein in der Gemeinde Freiberg am Neckar. Wir kombinieren Skifahren,
                            Snowboaden & Bergsport mit geselligem Après-Ski!</p>
                        <a class="btn btn-light btn-xl js-scroll-trigger" href="#activities"
                           v-smooth-scroll>Aktivitäten</a>
                    </div>
                </div>
            </div>
        </section>
        <!-- Portfolio-->
        <div id="activities">
            <div v-if="activities.length !== 0" class="container-fluid p-0">
                <div class="row no-gutters">
                    <div class="col-lg-4 col-sm-6" v-for="activity in activities" v-bind:key="activity.nid[0].value">
                        <a v-if="activity.field_image.length !== 0" class="portfolio-box"
                           :href="'/page/' + activity.nid[0].value">
                            <div class="stf-overflow" v-if="activity.field_image[0].url !== null">
                                <SmoothImg clazz="img-fluid " :src="activity.field_image[0].url"/>
                            </div>
                            <div class="portfolio-box-caption">
                                <div class="project-category text-white-50">
                                    {{formatDate(activity.created[0].value)}}
                                </div>
                                <div class="project-name"><b>{{activity.title[0].value}}</b></div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SmoothImg from "../components/SmoothImg";
    import moment from 'moment'
    import {mapGetters} from "vuex";

    export default {
        name: "Frontpage",
        components: {SmoothImg},
        computed: {
            // mix the getters into computed with object spread operator
            ...mapGetters([
                'activities'
            ])
        },
        data() {
            return {
                images: null,
                scrollPosition: null
            }
        },
        mounted() {
            this.$store.dispatch("getActivities").then(activities => {
                activities.forEach((activity) => {
                    console.log(activity);
                })
            });
        },
        methods: {
            formatDate(date) {
                return moment(date).lang("de").local().format('LLLL');
            }
        }
    }
</script>

<style lang="scss">
    .stf-overflow {
        overflow: hidden;
        max-height: 27rem;
    }
</style>
