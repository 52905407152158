<template>
    <transition name="fade">
        <div v-if="page" class="stf-text container mt-3" :id="'node-' + page.nid[0].value">
            <div class="mb-4">
                <h2>{{page.title[0].value}}</h2>
                <small><i>{{formatDate(page.created[0].value)}}</i></small>
            </div>
            <div v-html="page.body[0].value"></div>
            <div class="mb-3" v-if="page.field_gallery && page.field_gallery.length">
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-4" v-for="(image, index) in page.field_gallery"
                         v-bind:key="image.target_uuid">
                        <img class="img-thumbnail img-fluid mt-2"
                             :style="{  'cursor': 'zoom-in'}"
                             v-lazy="image.url"
                             :src="image.url"
                             :alt="image.alt"
                             @click="openGallery(index)"/>
                    </div>
                </div>
                <LightBox class="position-absolute" ref="lightbox" :showLightBox="false"
                          :media="mapImages(page.field_gallery)"></LightBox>
            </div>

        </div>
    </transition>
</template>

<script>
    import {mapGetters} from "vuex";
    import moment from "moment";
    import LightBox from 'vue-image-lightbox'

    export default {
        name: "Page",
        components: {
            LightBox,
        },
        computed: {
            // mix the getters into computed with object spread operator
            ...mapGetters([
                'page'
            ])
        },
        mounted() {
            if (this.$route) {
                this.$store.dispatch("getNode", this.$route.params.nodeId).then(() => {
                    this.$smoothScroll({
                        scrollTo: document.getElementById('node-' + this.$route.params.nodeId),
                        duration: 1000,
                        offset: -100,
                    })
                })
            }
        },
        methods: {
            openGallery(index) {
                this.$refs.lightbox.showImage(index)
            },
            formatDate(date) {
                return moment(date).lang("de").local().format('LLLL');
            },
            mapImages(images) {
                return images.map((image) => {
                    return {src: image.url, thumb: image.url}
                });
            }
        }
    }
</script>

<style scoped>
    .stf-text {
        word-break: break-word;
    }

    .center-block {
        float: none !important
    }
</style>
