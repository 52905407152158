import api from './api';

const actions = {
    getActivities() {
        return api.get(`/api/activities?_format=json`);
    },
    getActivity(id) {
        return api.get(`/node/${id}?_format=json`);
    }
};
export default actions
