import api from './api';

const actions = {
    getContactForm(id) {
        return api.get(`/contact/${id}?_format=json`);
    },
    createContact(id, name, mail, subject, message, copy, field_confirm) {
        return api.post(`entity/contact_message`, {
            "contact_form": [{"target_id": id}],
            "name": [{"value": name}],
            "mail": [{"value": mail}],
            "subject": [{"value": subject}],
            "message": [{"value": message}],
            "copy": [{"value": copy}],
            "field_confirm": [{"value": field_confirm}]
        });
    }
};
export default actions
